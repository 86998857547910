
<template>
    <div class="container">
        <div class="right_cont">
            <!-- <div class="title flex_center_start_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic4.png" />
                    HISTORY AND EVENTS
                </div>
            </div> -->
            <div class="his_box">
                <el-collapse v-model="activeNames">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <div class="head-title" style="background-color: #fff">Sources</div>
                        </template>
                        <div class="information-box-text ellipsistextt" v-for="(item, index) in sources" :key="index">
                            <a style="color: #1290c9; line-height: 35px" :href="item.external_link" target="_blank" rel="noopener noreferrer">{{ item.external_link }}</a>
                        </div>
                    </el-collapse-item>

                    <!-- <el-collapse-item title="反馈 Feedback" name="2">
                          <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
                          <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
                      </el-collapse-item> -->
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import { searchDetailsById } from '@/api/searchApi.js';
export default {
    data() {
        return {
            businesslist: [
                { name: 'Line of Business:', text: '' },
                { name: 'Products/Services:', text: '' },

                { name: 'Employees:', text: '' },
                { name: 'Listed:', text: '' },
                { name: 'Last Update:', text: '' },
            ],

            sources: [],
            icon: '',
            activeNames: ['1'],
        };
    },
    computed: {},
    watch: {
        $route: {
            // 深度观察监听
            deep: true,
        },
    },
    mounted() {
        this.getdetail();
    },

    methods: {
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                console.log(`res`, res);

                this.sources = res.data.attributes?.watchlist?.sources ? res.data.attributes.watchlist.sources : [];
            });
        },
    },
};
</script>
<style scoped>
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}
.right_cont {
  margin-top: 60px;
}
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pageBox {
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.his_box {
  width: 100%;
  padding: 30px 30px 30px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
  border-radius: 14px 14px 0px 0px;
}
.his_box /deep/ .el-timeline-item__wrapper {
  display: flex;
  padding-left: 14px;
}
.his_box /deep/ .el-timeline-item__node--normal {
  width: 8px;
  height: 8px;
  left: 1px;
}
.his_box /deep/ .el-timeline-item__node {
  background-color: #ff7600;
}
.his_box /deep/ .el-timeline-item__tail {
  border-left: 2px dashed #8497ab;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
  font-size: 16px;
  color: #8497ab;
  padding-top: 0;
}
.his_box /deep/ .el-timeline-item__content {
  padding-bottom: 24px;
  font-size: 16px;
  color: #022955;
  border-bottom: 1px solid #e7eaed;
  margin-left: 20px;
  flex: 1;
  line-height: 26px;
  margin-top: -5px;
}
.head-title {
  /* color: #022955; */
  font-size: 16px;
  line-height: 35px;
  /* margin-top: 20px; */
  color: #ff7600;
  /* padding-left: 20px; */
  font-weight: 700;
  font-family: 'element-icons';
}
.his_box /deep/ .el-table th.el-table__cell {
  background: #f2f5f9;
}
.tbl_search_box {
  /* position: absolute;
left: 0; */
display: flex;
  justify-content: space-between;
 margin-bottom: 20px;
}
.title_txt-right {
  display: flex;
}

.tbl_search {
  position: relative;
  width: 290px;
  height: 40px;
  z-index: 9;
}
.tbl_search /deep/ .el-input .el-input__inner {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(46, 118, 167, 0.4);
  border-radius: 8px;
  border: 1px solid #1290c9;
  padding: 0 40px 0 20px;
}
.tbl_search .el-input {
  height: 100%;
}
.information-box-text {
    font-size: 14px;
    color: #022955;
    line-height: 25px;
}
.information-box-text1 {
    font-size: 16px;
    /* color: #1290c9; */
    line-height: 25px;
}
</style>
